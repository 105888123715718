<template>
  <div class="bg-default">
    <van-row type="flex" justify="center">
      <van-col class="card-info" span="23">
        <van-row type="flex">
          <van-col span="4">
            <img class="img" :src="this.headimg" />
          </van-col>
          <van-col class="card-text">{{this.nickname}}</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row type="flex" justify="center" class="details-out">
      <van-col class="details-inner" span="23">
        <van-row>
          <van-col class="details-title">我的订单</van-col>
        </van-row>
        <van-row type="flex" justify="space-between" class="details-cell" @click="todaypay">
          <van-col>
            <van-row>
              <van-col>
                <img class="icon" :src="this.icon01" />
              </van-col>
              <van-col>乐捐详情</van-col>
            </van-row>
          </van-col>
          <van-col>
            <van-icon name="arrow" />
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" class="details-cell" @click="tolamp">
          <van-col>
            <van-row>
              <van-col>
                <img class="icon" :src="this.icon02" />
              </van-col>
              <van-col>供灯详情</van-col>
            </van-row>
          </van-col>
          <van-col>
            <van-icon name="arrow" />
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" class="details-cell" @click="toregister">
          <van-col>
            <van-row>
              <van-col>
                <img class="icon" :src="this.icon03" />
              </van-col>
              <van-col>佛事详情</van-col>
            </van-row>
          </van-col>
          <van-col>
            <van-icon name="arrow" />
          </van-col>
        </van-row>

        <van-row type="flex" justify="space-between" class="details-cell" @click="togolive">
          <van-col>
            <van-row>
              <van-col>
                <img class="icon" :src="this.icon04" />
              </van-col>
              <van-col>放生详情</van-col>
            </van-row>
          </van-col>
          <van-col>
            <van-icon name="arrow" />
          </van-col>
        </van-row>

        <van-row type="flex" justify="space-between" class="details-cell" @click="tobrand">
          <van-col>
            <van-row>
              <van-col>
                <img class="icon" :src="this.icon05" />
              </van-col>
              <van-col>牌位详情</van-col>
            </van-row>
          </van-col>
          <van-col>
            <van-icon name="arrow" />
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" class="details-cell" @click="tooffers">
          <van-col>
            <van-row>
              <van-col>
                <img class="icon" :src="this.icon06" />
              </van-col>
              <van-col>供养详情</van-col>
            </van-row>
          </van-col>
          <van-col>
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      headimg: "",
      icon01:
        "https://sms-1300492491.cos.ap-beijing.myqcloud.com/center/icon01.png",
      icon02:
        "https://sms-1300492491.cos.ap-beijing.myqcloud.com/center/icon02.png",
      icon03:
        "https://sms-1300492491.cos.ap-beijing.myqcloud.com/center/icon03.png",
      icon04:
        "https://sms-1300492491.cos.ap-beijing.myqcloud.com/center/icon04.png",
      icon05:
        "https://sms-1300492491.cos.ap-beijing.myqcloud.com/center/icon05.png",
      icon06:
        "https://sms-1300492491.cos.ap-beijing.myqcloud.com/center/icon06.png",
    };
  },
  created() {
    this.getavatar();
  },
  methods: {
    getavatar() {
      this.$api.center.getavatar().then((res) => {
        this.nickname = res.data.data.nickname;
        this.headimg = res.data.data.headimgurl;
        console.log(res.data.data);
      });
    },
    tolamp() {
      this.$router.push({
        path: "/center/lamp",
      });
    },
    toregister() {
      this.$router.push({
        path: "/center/register",
      });
    },
    togolive() {
      this.$router.push({
        path: "/center/golive",
      });
    },
    tobrand() {
      this.$router.push({
        path: "/center/brand",
      });
    },
    tooffers() {
      this.$router.push({
        path: "/center/offers",
      });
    },
    todaypay() {
      this.$router.push({
        path: "/center/daypay",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &-info {
    height: 8rem;
    margin-top: 0.6rem;
    background: linear-gradient(to left, rgb(243, 181, 100), rgb(221, 150, 58));
    border-radius: 0.4rem;
  }
  &-text {
    padding-left: 1.4rem;
    padding-top: 0.6rem;
    font-size: 18px;
    color: $text-black;
  }
}
.details {
  &-title {
    padding-top: 0.6rem;
    font-size: 18px;
    font-weight: bold;
    color: $text-black;
  }
  &-cell {
    height: 1.5rem;
    font-size: 16px;
    margin-top: 1rem;
    color: $text-gray;
    border-bottom: 1px solid $border-gray;
  }
  &-out {
    margin-top: 1.6rem;
  }
  &-inner {
    border-radius: 0.4rem;
    margin-bottom: 18rem;
    padding: 0rem 0.8rem 1.6rem;
    background-color: $bg-white;
  }
}
.img {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0.6rem;
}
.icon {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1.2rem;
  height: 1.2rem;
  padding-right: 0.3rem;
}
</style>